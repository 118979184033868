import { Pipe, PipeTransform } from '@angular/core';

import { RoleType } from 'src/app/shared/constants/role-type.constant';
import { ViewEvent } from 'src/app/shared/models';

@Pipe({
	name: 'viewEventsFilter',
	standalone: true,
})
export class ViewEventsFilterPipe implements PipeTransform {
	transform(viewEvents: ViewEvent[], assignedToIds: number[], loggedInUserId: number, roleId: number): ViewEvent[] {
		return viewEvents.filter(
			v =>
				(!assignedToIds?.length || assignedToIds.includes(v.assignedToId)) &&
				(roleId === RoleType.system || !v.assignedToId || v.assignedToId === loggedInUserId),
		);
	}
}
